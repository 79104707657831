import React, { useContext } from "react";
import Card from "../components/Card";
import { ThemeContext } from "../themeProvider";

const Projects = () => {
  const theme = useContext(ThemeContext);
  const darkMode = theme.state.darkMode;

  return (
    <div id="projects" className={darkMode ? "bg-white text-black" : "bg-gray-900 text-white"}>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-24 pb-12">
        <h2 className="text-5xl font-bold text-center">Projects</h2>
        <h4 className="mt-16 text-3xl font-semibold text-blue-600">Crafted with Innovation</h4>
        <div className="flex justify-between items-stretch flex-wrap">
          <Card
            title="E-commerce Website"
            description="Designed and developed an e-commerce platform, including a user-friendly front-end and a robust back-end system. Utilized Python and Django for back-end development. The platform integrates secure payment gateways and advanced product search functionalities, ensuring a seamless shopping experience for users. Additionally, implemented responsive design for optimal performance across devices, enhancing accessibility and user satisfaction."
            link="https://example.com/ecommerce"
            imageUrl="https://codemithra.com/wp-content/uploads/2020/09/web-design-development-blog-2.jpg"
          />

          <Card
            title="Personal Finance Mobile App"
            description="Created a mobile app for personal finance management, allowing users to track expenses and set financial goals. Utilized Java and Android Studio for app development."
            link="https://example.com/finance-app"
            imageUrl="https://www.viithiisys.com/images/uploads/App-Development2.jpg"
          />

          <Card
            title="Library Management System"
            description="Create a Java application for managing a library's catalog. Include features for book check-in, check-out, inventory management, and user authentication."
            link="https://example.com/library-app"
            imageUrl="https://copyassignment.com/wp-content/uploads/2022/09/Library-Management-System-Project-in-Java-768x576.jpg"
          />

          <Card
            title="Inventory Management System"
            description="Developed a comprehensive C++ application tailored for local businesses, facilitating efficient management of inventory, sales, and stock levels. Incorporated advanced features such as real-time data analysis and customizable reporting tools, empowering businesses to make informed decisions and streamline operations effectively."
            link="https://example.com/inventory-app"
            imageUrl="https://www.waoconnect.com/wp-content/uploads/2020/07/Inventory-Management-System-Pic.jpg"
          />
        </div>
      </div>
    </div>
  );
};

export default Projects;
