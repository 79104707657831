import React, { useContext } from "react";
import { motion } from "framer-motion";
import { ThemeContext } from "../themeProvider";
import { techStack } from "../constants";

const About = () => {
  const theme = useContext(ThemeContext);
  const darkMode = theme.state.darkMode;

  return (
    <div id="about" className={`bg-${darkMode ? "white" : "gray-900"}`}>
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 md:mt-0 pt-24 pb-12">
        <h2 className={`text-5xl font-bold px-4 md:px-0 text-center ${darkMode ? "" : "text-white"}`}>
          About Me
        </h2>

        <motion.div>
          <h4 className="mt-12 text-3xl font-semibold text-blue-500">Personal Introduction</h4>
          <motion.p className={`mt-4 text-xl text-justify ${darkMode ? "text-gray-500" : "text-white"}`}>
            A dedicated and passionate Computer Science student with a strong interest in web development, full-stack programming, and mobile app development. Skilled in Python, Java, C, C++, and proficient in database management. Eager to apply my knowledge and contribute to innovative projects and solutions.
          </motion.p>
        </motion.div>

        <motion.div>
          <h4 className="mt-12 text-3xl font-semibold text-blue-500">Technical Proficiency</h4>
          <motion.p className={`mt-4 text-xl text-justify ${darkMode ? "text-gray-500" : "text-white"}`}>
            An enthusiastic Computer Science scholar with a fervor for web development, full-stack programming, and mobile app design. Proficient in Python, Java, C, C++, and adept in database administration. Eager to apply my prowess in crafting cutting-edge solutions for complex technical challenges.
          </motion.p>
        </motion.div>

        <motion.div className="flex flex-wrap mt-8 justify-between">
          {techStack.map((el, index) => (
            <motion.div
              key={index}
              initial="hidden"
              whileInView={"visible"}
              variants={{
                visible: {
                  y: 0,
                  opacity: 1,
                  transition: {
                    type: "spring",
                  },
                },
                hidden: { opacity: 1, y: 80 },
              }}
              className="py-2 px-4 bg-gray-50 md:m-4 mx-2 mt-6 rounded-lg flex items-center hover:scale-125 cursor-pointer md:w-48 w-40"
            >
              <img alt="" src={el.link} className="w-12" />
              <h4 className="text-md ml-4">{el.name}</h4>
            </motion.div>
          ))}
        </motion.div>
      </div>
    </div>
  );
};

export default About;
